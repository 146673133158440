import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import type {
  MessageCustomFieldsForViewQuery,
  MessageCustomFieldsForViewQueryVariables
} from '../../../types/graphql-types';
import useTranslation from '../../useTranslation';
import { getMessageCustomFieldsAndValuesForView } from '../MessageCustomFieldsHelper';
import messageCustomFieldsQuery from './MessageCustomFieldsForView.query.graphql';
import localStyles from './MessageCustomFields.module.pcss';

interface Props {
  /**
   * The message id to display the custom fields for.
   */
  messageId: string;
}

/**
 * Message custom fields.
 *
 * @constructor
 *
 * @author Lucas Fernandez
 */
const MessageCustomFields: React.FC<React.PropsWithChildren<Props>> = ({ messageId }: Props) => {
  const cx = useClassNameMapper(localStyles);

  const { formatMessage, formatMessageWithFallback } = useTranslation(
    EndUserComponent.MESSAGE_CUSTOM_FIELDS
  );

  function getLabelForCustomField(customFieldName: string): string {
    return formatMessageWithFallback(customFieldName, 'CustomField.default.label', {
      name: customFieldName
    });
  }

  const { loading: messageLoading, data: messageContext } = useQueryWithTracing<
    MessageCustomFieldsForViewQuery,
    MessageCustomFieldsForViewQueryVariables
  >(module, messageCustomFieldsQuery, {
    variables: {
      id: messageId
    }
  });

  if (messageLoading) {
    return null;
  }

  const customFieldsValues = getMessageCustomFieldsAndValuesForView(
    messageContext.message?.customFields
  );

  return (
    <>
      {Object.keys(customFieldsValues).length > 0 && (
        <div
          className={cx('lia-g-my-20 lia-custom-fields-container')}
          data-testid="MessageCustomFields"
        >
          <span className={cx('lia-section-name')}>{formatMessage('sectionName')}</span>
          {Object.keys(customFieldsValues).map(name => {
            const label: string = getLabelForCustomField(name);
            const value: string = customFieldsValues[name];

            return (
              <span key={name} className={cx('lia-custom-field')}>
                {label + ': ' + value}
              </span>
            );
          })}
        </div>
      )}
    </>
  );
};

export default MessageCustomFields;
