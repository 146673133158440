import EmptyState from '@aurora/shared-client/components/common/EmptyState/EmptyState';
import {
  PagerLoadMoreVariant,
  PagerPosition,
  PagerVariant
} from '@aurora/shared-client/components/common/Pager/enums';
import { PanelType } from '@aurora/shared-client/components/common/Panel/enums';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import Icons from '@aurora/shared-client/icons';
import type { MessageConstraints } from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import React, { useContext } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { ItemType, MessageViewVariant } from '../../../types/enums';
import type {
  MessageAuthorFragment,
  MessageBoardFragment,
  MessageViewFragment,
  MessageViewsQuery,
  MessageViewsQueryVariables,
  ThreadedReplyListFragment
} from '../../../types/graphql-types';
import PaneledItemList from '../../common/List/PaneledItemList/PaneledItemList';
import type { ItemViewTypeAndProps } from '../../entities/types';
import useEntityViewQuery from '../../useEntityViewQuery';
import useTranslation from '../../useTranslation';
import { messageListMenuItemMap } from '../MessageListMenu/MessageListMenu';
import type { MessageViewStandardProps } from '../MessageView/types';
import messageViewsQuery from '../MessageViews.query.graphql';
import type { MessageList } from '../types';
import useMessageSort from '../useMessageSort';

interface MessageReplyListProps extends MessageList {
  /**
   * The message to display a list of threaded replies for.
   */
  message: ThreadedReplyListFragment & MessageBoardFragment & MessageAuthorFragment;
  /**
   * Whether to use the empty state if there are no reply messages.
   */
  useEmpty?: boolean;
}

/**
 * Displays a linear list of replies for a message. The query to get the replies will be
 * executed as part of rendering this component and will use the ancestorId constraint.
 * The replies rendered via this component will not have indentation based on depth.
 *
 * @constructor
 * @author Subhiksha Venkatesan
 */
const LinearReplyList: React.FC<React.PropsWithChildren<MessageReplyListProps>> = ({
  message,
  viewVariant = {
    type: MessageViewVariant.STANDARD,
    props: {
      useAccordionForReply: false
    }
  },
  listVariant,
  pagerVariant = {
    type: PagerVariant.LOAD_MORE,
    props: {
      position: PagerPosition.START,
      variant: PagerLoadMoreVariant.BORDERED
    }
  },
  pageSize = 10,
  useEmpty = false
}) => {
  const { authUser, contextUser } = useContext(AppContext);
  const user = contextUser ?? authUser;
  const cx = useClassNameMapper();
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.LINEAR_REPLY_LIST
  );

  const { sortMenuItem, loading: repliesSortOrderLoading } = useMessageSort();

  const { sorts } = messageListMenuItemMap[sortMenuItem];

  const constraints: MessageConstraints = {
    ancestorId: {
      eq: message.id
    }
  };

  const queryResult = useEntityViewQuery<
    ItemType.MESSAGE,
    MessageViewsQuery,
    MessageViewsQueryVariables
  >(module, ItemType.MESSAGE, viewVariant, messageViewsQuery, {
    variables: {
      first: pageSize,
      constraints,
      sorts
    }
  });

  const useAccordionForReply =
    viewVariant.type === MessageViewVariant.STANDARD &&
    (viewVariant.props as MessageViewStandardProps).useAccordionForReply;

  const linearPagerVariant = {
    type: pagerVariant.type,
    props: {
      ...pagerVariant.props,
      className: cx('lia-g-mb-10 lia-g-pt-5', {
        'lia-g-ml-md-20': useAccordionForReply
      })
    }
  };

  if (textLoading || repliesSortOrderLoading) {
    return null;
  }

  function renderNoReplies(): React.ReactElement {
    return (
      <EmptyState
        icon={Icons.RepliesIcon}
        description={
          message.author.login !== user?.login ? formatMessage('noRepliesDescription') : null
        }
        title={formatMessage('noRepliesTitle')}
      />
    );
  }

  return (
    <PaneledItemList<
      MessageViewFragment,
      ItemType.MESSAGE,
      ItemViewTypeAndProps<ItemType.MESSAGE, MessageViewVariant>,
      MessageViewsQuery,
      MessageViewsQueryVariables
    >
      type={ItemType.MESSAGE}
      variant={viewVariant}
      queryResult={queryResult}
      listVariant={listVariant}
      pagerVariant={linearPagerVariant}
      pageSize={pageSize}
      panel={PanelType.NONE}
      className={cx('lia-g-ml-md-25 lia-g-ml-15')}
      empty={renderNoReplies}
      useEmpty={useEmpty}
    />
  );
};

export default LinearReplyList;
