import React, { useContext } from 'react';
import { IconColor, IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import Icon from '@aurora/shared-client/components/common/Icon/Icon';
import Icons from '@aurora/shared-client/public/static/graphics/processed/enums';
import ArticleLink from '../GuideNavigation/ArticleLink/ArticleLink';
import { useClassNameMapper } from 'react-bootstrap';
import localStyles from './GuideBottomNavigationItem.module.pcss';
import type { KbArticleViewFragment } from '../../../types/graphql-types';
import { EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';

interface Props {
  /**
   * Alignment of the item - left or right
   */
  alignRight?: boolean;
  /**
   * current Guide Id
   */
  currentGuideId?: string;
  /**
   * Heading of link
   */
  linkHeading: string;
  /**
   * The article content.
   */
  article: KbArticleViewFragment;
  /**
   * Whether to use manual sort navigation.
   */
  useManualSortNav: boolean;
}

/**
 * Guide Bottom navigation item
 *
 * @author Kunal Punjrath
 */
const GuideBottomNavigationItem: React.FC<Props> = ({
  alignRight,
  currentGuideId,
  linkHeading,
  article,
  useManualSortNav
}) => {
  const cx = useClassNameMapper(localStyles);
  const linkIcon = alignRight ? Icons.ChevronRightIcon : Icons.ChevronLeftIcon;
  const { subject } = article;
  const {
    contextMessage: { board, uid }
  } = useContext(AppContext);

  return (
    <li className={cx('lia-li', { 'lia-align-right': alignRight })}>
      <ArticleLink
        article={{ ...article, board, uid }}
        parentGuideId={currentGuideId}
        useShallow={true}
        useManualSortNav={useManualSortNav}
        additionalQueryParams={{
          [EndUserQueryParams.NODE_ID]: article.board.id,
          [EndUserQueryParams.ARTICLE_ID]: article.id
        }}
      >
        <a className={cx('lia-link')}>
          <Icon
            icon={linkIcon}
            color={IconColor.GRAY_800}
            size={IconSize.PX_24}
            className={cx('lia-icon')}
          />
          <span className={cx('lia-heading')}>{linkHeading}</span>
          <span className={cx('lia-subheading lia-g-clamp lia-g-clamp-2')}>{subject}</span>
        </a>
      </ArticleLink>
    </li>
  );
};

export default GuideBottomNavigationItem;
