import ThemeContext from '@aurora/shared-client/components/context/ThemeContext/ThemeContext';
import useCrossOriginAttribute from '@aurora/shared-client/components/useCrossOriginAttribute';
import type { OccasionTopicMessage } from '@aurora/shared-generated/types/graphql-schema-types';
import {
  BackgroundExtension,
  CoverImageStyle,
  CoverImageTitlePosition
} from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import UrlHelper from '@aurora/shared-utils/helpers/urls/UrlHelper/UrlHelper';
import * as React from 'react';
import { useContext } from 'react';
import { Image, useClassNameMapper } from 'react-bootstrap';
import { OccasionZoomMeetingVariant } from '../../../types/enums';
import type { BlogCoverImageFragment } from '../../../types/graphql-types';
import OccasionZoomMeeting from '../../occasions/OccasionZoomMeeting/OccasionZoomMeeting';
import useAutoRefresh from '../../useAutoRefresh';
import useTranslation from '../../useTranslation';
import localStyles from './MessageCoverImage.module.pcss';
import dynamic from 'next/dynamic';

const OccasionVideoPreview = dynamic(
  () => import('../../occasions/OccasionVideoPreview/OccasionVideoPreview'),
  { ssr: false }
);

interface Props {
  /**
   * Position of the cover image title.
   */
  titlePosition?: CoverImageTitlePosition;
  /**
   * Width variations in cover image.
   */
  imageStyle?: CoverImageStyle;
  /**
   * The alternative text for the cover image
   */
  altText?: string;
  /**
   * The Message to add cover image to.
   */
  message: OccasionTopicMessage | BlogCoverImageFragment;
  /**
   * Classname to be applied for the component.
   */
  className?: string;
  /**
   * Whether this is rendered from create/edit view
   */
  isCreateOrEditView?: boolean;
}

/**
 * Display Cover Image for a Message.
 * @author Arjun Krishna
 */
const MessageCoverImage: React.FC<React.PropsWithChildren<Props>> = ({
  message,
  titlePosition = CoverImageTitlePosition.Bottom,
  imageStyle = CoverImageStyle.Standard,
  altText = '',
  className,
  isCreateOrEditView = true
}) => {
  const cx = useClassNameMapper(localStyles);
  const { loading: textLoading } = useTranslation(EndUserComponent.MESSAGE_COVER_IMAGE);
  const href = message.coverImage?.url;
  const { theme } = useContext(ThemeContext);
  const { startTime, liveVideoIframe, endTime, liveStreamUrl } =
    (message as OccasionTopicMessage)?.occasionData || {};
  const isInProgress = useAutoRefresh(startTime, endTime, liveVideoIframe, 3600000);
  const isZoomOccasion = liveVideoIframe && liveVideoIframe?.match('zoom.us/j/');
  const crossOrigin = useCrossOriginAttribute();

  if (textLoading || (!liveVideoIframe && !href)) {
    return null;
  }

  const adjustedImageUrl =
    href &&
    UrlHelper.adjustImageServerPageUrl(
      href,
      {
        height: 2000,
        width: 2000
      },
      true,
      null,
      false
    );
  const finalTitlePosition = titlePosition ?? CoverImageTitlePosition.Bottom;
  const finalImageStyle = imageStyle ?? CoverImageStyle.Standard;
  const useWidthOfPageContent =
    theme?.basics?.pageWidthStyle === BackgroundExtension.WidthOfPageContent &&
    finalImageStyle === CoverImageStyle.Wide;

  function renderLiveStreamVideo(): React.ReactElement {
    return isZoomOccasion ? (
      <OccasionZoomMeeting
        zoomUrl={liveVideoIframe}
        coverImageUrl={adjustedImageUrl}
        endTime={endTime}
        variant={OccasionZoomMeetingVariant.LARGE}
      />
    ) : (
      <OccasionVideoPreview />
    );
  }

  function renderBannerImage(): React.ReactElement {
    return (
      <Image
        src={adjustedImageUrl}
        alt={altText}
        className={cx('lia-cover-image')}
        data-testid="MessageCoverImage-Image"
        crossOrigin={crossOrigin(adjustedImageUrl)}
      />
    );
  }

  if (!adjustedImageUrl && !liveStreamUrl) {
    return null;
  }

  return (
    <div
      className={cx(
        'justify-content-center',
        'lia-cover-image-wrap',
        `lia-cover-image-wrap-${finalTitlePosition.toLowerCase()}`,
        { [`lia-cover-image-wrap-${finalImageStyle.toLowerCase()}`]: message?.coverImage !== null },
        { 'lia-cover-image-wrap-width-is-page-content': useWidthOfPageContent },
        { 'lia-cover-video': liveVideoIframe && isInProgress && !isZoomOccasion },
        { 'lia-occasion-zoom': liveVideoIframe && isInProgress && isZoomOccasion },
        className
      )}
    >
      {!!liveVideoIframe && isInProgress && !isCreateOrEditView
        ? renderLiveStreamVideo()
        : renderBannerImage()}
    </div>
  );
};

export default MessageCoverImage;
