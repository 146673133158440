import useDateTime from '@aurora/shared-client/components/useDateTime';
import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import type {
  Article,
  RevisionConstraints
} from '@aurora/shared-generated/types/graphql-schema-types';
import {
  ContentWorkflowState,
  ConversationStyle
} from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import type {
  MessageRevisionFragment,
  MessageRevisionsQuery,
  MessageRevisionsQueryVariables
} from '../../../types/graphql-types';
import TKBContributorsList from '../../users/TKBContributorsList/TKBContributorsList';
import useTranslation from '../../useTranslation';
import { useIsPreviewMode } from '../useCurrentOrPreviewMessage';
import localStyles from './MessageRevision.module.pcss';
import messageRevisionsMetadataQuery from './MessageRevisionsMetadata.query.graphql';

interface Props {
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;
  /**
   * The message to display the revision and last updated for.
   */
  message: MessageRevisionFragment;
  /**
   * Show published or last updated date
   */
  showTimestamp?: boolean;
  /**
   * Show contributor list
   */
  showContributorList?: boolean;
  /**
   * Show version
   */
  showVersion?: boolean;
}

/**
 * Displays the current revision of message and last updated time.
 *
 * @author Sravan Reddy
 */
const MessageRevision: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  message,
  showTimestamp = true,
  showContributorList = true,
  showVersion = true
}) => {
  const cx = useClassNameMapper(localStyles);
  const { formatMessageAsNode, loading: textLoading } = useTranslation(
    EndUserComponent.MESSAGE_REVISION
  );
  const { getUserPreferredDateFormat, formatAbsoluteDateTime } = useDateTime();
  const isPreview = useIsPreviewMode();
  const isTkb = (message as Article)?.board?.conversationStyle === ConversationStyle.Tkb;
  const constraints: RevisionConstraints = {
    isPublished: {
      eq: !isPreview
    }
  };

  const { data: revisionsData, loading: revisionsLoading } = useQueryWithTracing<
    MessageRevisionsQuery,
    MessageRevisionsQueryVariables
  >(module, messageRevisionsMetadataQuery, {
    variables: {
      id: message?.id,
      useRevisionMessage: false,
      revisionsFirst: 1,
      constraints
    },
    fetchPolicy: isPreview ? 'cache-and-network' : 'cache-first',
    nextFetchPolicy: 'cache-first'
  });

  if (textLoading || revisionsLoading || !revisionsData) {
    return null;
  }

  const { currentRevision, latestVersion: version, revisions } = revisionsData.message;

  const { contentWorkflow } = revisionsData.message as Article;

  const lastUpdatedDate: React.ReactElement = (
    <span title={formatAbsoluteDateTime(currentRevision?.lastEditTime)}>
      {getUserPreferredDateFormat(currentRevision?.lastEditTime)}
    </span>
  );

  /**
   * Render last updated date based on the ContentWorkflowState
   */
  function renderLastUpdatedDate() {
    if (contentWorkflow?.state !== ContentWorkflowState.Publish && isPreview) {
      return formatMessageAsNode('lastUpdatedDateDraft', {
        date: lastUpdatedDate
      });
    } else {
      const publishCount = revisions?.totalCount;
      return formatMessageAsNode('lastUpdatedDatePublished', {
        publishCount,
        date: lastUpdatedDate
      });
    }
  }

  return (
    <div className={cx(className, 'lia-revision')}>
      {(showTimestamp || showContributorList) && (
        <div className={cx('d-flex align-items-center')}>
          {showTimestamp && (
            <span data-testid="MessageTimestamp" className={cx('text-body lia-publish-date')}>
              {renderLastUpdatedDate()}
            </span>
          )}
          {showContributorList && isTkb && (
            <>
              <span
                role="separator"
                className={cx('lia-g-divider-element lia-tkb-contributors-divider')}
              />
              <TKBContributorsList message={message as Article} />
            </>
          )}
        </div>
      )}
      {showVersion && version && (
        <span data-testid="MessageVersion" className={cx('lia-revision-text rounded-sm')}>
          {formatMessageAsNode('version', {
            major: version.major,
            minor: isPreview ? version.minor : 0
          })}
        </span>
      )}
    </div>
  );
};

export default MessageRevision;
