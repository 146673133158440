import Button from '@aurora/shared-client/components/common/Button/Button';
import { ButtonVariant } from '@aurora/shared-client/components/common/Button/enums';
import { IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import List from '@aurora/shared-client/components/common/List/List';
import type { Article, UserEdge } from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import React, { useState } from 'react';
import { Modal, useClassNameMapper } from 'react-bootstrap';
import type { ItemType } from '../../../types/enums';
import { UserViewVariant } from '../../../types/enums';
import type { MessageViewFragment } from '../../../types/graphql-types';
import type { ItemViewTypeAndProps } from '../../entities/types';
import useTranslation from '../../useTranslation';
import UserView from '../UserView/UserView';
import localStyles from './TKBContributorsList.module.pcss';

interface Props {
  /**
   * The message to display Contributors.
   */
  message: MessageViewFragment;
}

const TKBContributorsList: React.FC<React.PropsWithChildren<Props>> = ({ message }) => {
  const cx = useClassNameMapper(localStyles);
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.TKB_CONTRIBUTORS_LIST
  );
  const [showModal, setShowModal] = useState<boolean>(false);

  if (textLoading) {
    return null;
  }

  const { author } = message as Article;
  const coAuthors =
    (message as Article)?.coAuthors?.edges?.map((coAuthor: UserEdge) => coAuthor?.node) ?? [];

  const contributors =
    (message as Article)?.contributors?.edges?.map((contributor: UserEdge) => contributor?.node) ??
    [];

  const contributorsList = [author, ...coAuthors, ...contributors];

  const viewVariant: ItemViewTypeAndProps<ItemType.USER, UserViewVariant.INLINE> = {
    type: UserViewVariant.INLINE,
    props: {
      useAvatar: true,
      useRank: true,
      useLink: true,
      useHoverCard: true,
      avatarSize: IconSize.PX_40
    }
  };

  function showContributorsListModal() {
    return (
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="sm"
        centered
        scrollable={true}
        backdrop={true}
        aria-label={'TKBContributorsList'}
        data-testid="TKBContributorsList"
        className={cx('lia-modal')}
      >
        <Modal.Header closeButton className={cx('lia-header')}>
          <Modal.Title className={cx('lia-title')} id={'"TKBContributorsListDialog.Title"'}>
            {formatMessage('contributorsList.modal.title')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={cx('lia-body')} data-testid="TKBContributorsListDialog.Body">
          <List items={contributorsList}>
            {contributor => (
              <UserView
                entity={contributor}
                variant={viewVariant}
                className={cx('lia-contributors-user')}
                key={contributor.id}
              />
            )}
          </List>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <>
      <Button
        data-testid="TKBContributorsListButton"
        variant={ButtonVariant.UNSTYLED}
        onClick={() => setShowModal(true)}
        className={cx('lia-btn-icon tkb-view-contributors')}
      >
        {formatMessage('viewContributors.link.text')}
      </Button>
      {showModal && showContributorsListModal()}
    </>
  );
};

export default TKBContributorsList;
