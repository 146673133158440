import type {
  CustomBooleanField,
  CustomDateTimeField,
  CustomFloatField,
  CustomIntField,
  CustomLongField,
  CustomStringField,
  CustomIntListField,
  CustomStringListField,
  CustomFloatListField
} from '@aurora/shared-generated/types/graphql-schema-types';
import type { CustomFieldsFragment } from '../../types/graphql-types';

/**
 * This function returns a list of custom fields and its values. This is to be used only
 * on pages where the custom fields are to be used for display purposed and not for user
 * actions. If a field doesn't have a value set against it, we filter that field
 * @param customFields
 * @returns
 */
export function getMessageCustomFieldsAndValuesForView(
  customFields: CustomFieldsFragment['customFields']
): Record<string, string> {
  const customFieldValues: Record<string, string> = {};
  customFields?.forEach(field => {
    const { name, __typename } = field;
    switch (__typename) {
      case 'CustomBooleanField': {
        const booleanField = field as CustomBooleanField;
        if (booleanField.booleanValue != null) {
          customFieldValues[name] = `${booleanField.booleanValue}`;
        }
        break;
      }
      case 'CustomFloatField': {
        const floatField = field as CustomFloatField;
        if (floatField.floatValue != null) {
          customFieldValues[name] = `${floatField.floatValue}`;
        }
        break;
      }
      case 'CustomLongField': {
        const longField = field as CustomLongField;
        if (longField.longValue != null) {
          customFieldValues[name] = `${longField.longValue}`;
        }
        break;
      }
      case 'CustomStringField': {
        const stringField = field as CustomStringField;
        if (stringField.stringValue != null && stringField.stringValue !== '') {
          customFieldValues[name] = `${stringField.stringValue}`;
        }
        break;
      }
      case 'CustomDateTimeField': {
        const dateTimeField = field as CustomDateTimeField;
        if (dateTimeField.dateTimeValue != null) {
          customFieldValues[name] = `${dateTimeField.dateTimeValue}`;
        }
        break;
      }
      case 'CustomIntField': {
        const intField = field as CustomIntField;
        if (intField.intValue != null) {
          customFieldValues[name] = `${intField.intValue}`;
        }
        break;
      }
      case 'CustomIntListField': {
        const intListField = field as CustomIntListField;
        if (intListField.intListValue?.length > 0) {
          customFieldValues[name] = intListField.intListValue.join(', ');
        }
        break;
      }
      case 'CustomFloatListField': {
        const floatListField = field as CustomFloatListField;
        if (floatListField.floatListValue?.length > 0) {
          customFieldValues[name] = floatListField.floatListValue.join(', ');
        }
        break;
      }
      case 'CustomStringListField': {
        const stringListField = field as CustomStringListField;
        if (stringListField.stringListValue?.length > 0) {
          customFieldValues[name] = stringListField.stringListValue.join(', ');
        }
        break;
      }
    }
  });
  return customFieldValues;
}
