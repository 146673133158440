import { IconColor, IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import Icon from '@aurora/shared-client/components/common/Icon/Icon';
import type { ListVariantTypeAndProps } from '@aurora/shared-client/components/common/List';
import { ListVariant } from '@aurora/shared-client/components/common/List/enums';
import Loading from '@aurora/shared-client/components/common/Loading/Loading';
import { PagerVariant } from '@aurora/shared-client/components/common/Pager/enums';
import type { PagerVariantTypeAndProps } from '@aurora/shared-client/components/common/Pager/types';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import Icons from '@aurora/shared-client/icons';
import { LoadingSize, LoadingSpacing } from '@aurora/shared-client/types/enums';
import type {
  AttachmentConnection,
  AttachmentEdge,
  BlogTopicMessage,
  MessagesAttachmentConstraints,
  RevisionConnection
} from '@aurora/shared-generated/types/graphql-schema-types';
import type { EndUserPages, EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import IdConverter from '@aurora/shared-utils/graphql/IdConverter/IdConverter';
import dynamic from 'next/dynamic';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { AttachmentViewVariant, ItemType } from '../../../types/enums';
import type {
  AttachmentListQuery,
  AttachmentListQueryVariables,
  AttachmentViewFragment,
  MessageAttachmentsFragment,
  MessageViewFragment,
  RevisionAttachmentsFragment,
  RevisionViewFragment
} from '../../../types/graphql-types';
import ItemList from '../../common/List/ItemList';
import type { ItemViewTypeAndProps } from '../../entities/types';
import ImageOption from '../../images/ImagePreviewModal/ImageOption';
import {
  getFirstRevision,
  useIsPreviewMode,
  useMessageRevisionsQuery
} from '../../messages/useCurrentOrPreviewMessage';
import type { AttachmentListProps } from '../types';
import localStyles from './AttachmentList.module.pcss';
import attachmentsQuery from './MessagesAttachmentList.query.graphql';

const ImagePreviewModal = dynamic(
  () => import('../../images/ImagePreviewModal/ImagePreviewModal'),
  {
    ssr: false
  }
);

interface Props extends AttachmentListProps {
  /**
   * The message the attachments belong to.
   */
  message: MessageViewFragment;
  /**
   * The class name(s) applied to the component element.
   */
  className?: string;
  /**
   * Whether to show attachment preview or not.
   */
  useAttachmentPreview?: boolean;
  /**
   * Whether the user can edit the message.
   */
  canEdit?: boolean;
}

/**
 * Gets the `ImageOption` array from the message's attachments.
 *
 * @param revision the current revision object.
 */
function getImageOptions(
  revision: RevisionAttachmentsFragment | MessageAttachmentsFragment
): ImageOption[] {
  return revision?.attachments?.edges
    .filter(edge => edge?.node?.contentType?.startsWith('image/'))
    .map(attachmentEdge => new ImageOption((attachmentEdge as AttachmentEdge).node));
}

/**
 * A list of attachments for the given message.
 *
 * @constructor
 * @author Dolan Halbrook
 */

const AttachmentList: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  message,
  pageSize = 5,
  listVariant,
  useAttachmentPreview = true,
  canEdit = false,
  pagerVariant = {
    type: PagerVariant.LOAD_MORE,
    props: {
      loadingSpacing: LoadingSpacing.NONE,
      loadingSize: LoadingSize.SM,
      useCaret: false
    }
  }
}) => {
  const cx = useClassNameMapper(localStyles);
  const tenant = useContext(TenantContext);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const selectedImage = useRef<ImageOption>(null);
  const isPreviewMode = useIsPreviewMode() && canEdit;

  const { data: revisionsData, loading: revisionsLoading } = useMessageRevisionsQuery(
    {
      id: message.id,
      revisionsFirst: 1,
      useRevisionMessage: true,
      useRevisionAttachments: true
    },
    !isPreviewMode
  );

  const revision: RevisionViewFragment = isPreviewMode
    ? (getFirstRevision(
        (revisionsData?.message as BlogTopicMessage)?.revisions as RevisionConnection
      ) as RevisionViewFragment)
    : null;
  const revisionNumber = isPreviewMode ? revision?.revisionNum : message.revisionNum;

  const constraints: MessagesAttachmentConstraints = {
    revisionNum: {
      eq: revisionNumber
    }
  };

  const loading = isPreviewMode && (revisionsLoading || !revisionsData);
  const queryResult = useQueryWithTracing<AttachmentListQuery, AttachmentListQueryVariables>(
    module,
    attachmentsQuery,
    {
      variables: {
        constraints,
        messageId: message.id,
        first: pageSize
      },
      fetchPolicy: isPreviewMode ? 'cache-and-network' : 'cache-first',
      nextFetchPolicy: 'cache-first',
      skip: loading || IdConverter.isOptimistic(tenant, message?.id)
    }
  );
  const imageOptionsSource: RevisionViewFragment | MessageViewFragment = revision ?? message;
  const imageOptionsRef = useRef<ImageOption[]>(getImageOptions(imageOptionsSource));

  useEffect(() => {
    // Rebuild imageOptions whenever the AttachmentList re-renders
    imageOptionsRef.current = getImageOptions(queryResult?.data?.message ?? imageOptionsSource);
  }, [imageOptionsRef, imageOptionsSource, queryResult]);

  /**
   * Handler for `onAttachmentClick` callback on an image. Opens the `ImagePreviewModal` and sets the `ImageOption`s.
   *
   * @param selectedImageOption Selected image attachment's `ImageOption` data
   */
  function handleItemClick(selectedImageOption: ImageOption): void {
    const imageId = selectedImageOption.id;
    selectedImage.current = imageOptionsRef.current.find(imageOption => imageOption.id === imageId);
    setShowPreviewModal(true);
  }

  const viewVariant: ItemViewTypeAndProps<ItemType.ATTACHMENT, AttachmentViewVariant> = {
    type: AttachmentViewVariant.CHIP,
    props: {
      onImageAttachmentClick: useAttachmentPreview ? handleItemClick : undefined,
      isPreview: useAttachmentPreview
    }
  };

  const finalListVariant: ListVariantTypeAndProps<AttachmentViewFragment> = listVariant ?? {
    type: ListVariant.UNWRAPPED
  };

  if (loading) {
    return <Loading />;
  }

  const AttachmentPager: React.FC<React.PropsWithChildren<unknown>> = () => {
    return (
      <Icon
        icon={Icons.EllipsisIcon}
        color={IconColor.BODY_COLOR}
        size={IconSize.PX_16}
        testId="AttachmentList.EllipsisIcon"
      />
    );
  };

  const decoratedPagerVariant: PagerVariantTypeAndProps<EndUserPages, EndUserQueryParams> = {
    ...pagerVariant
  };
  decoratedPagerVariant.props.className = cx('lia-pager');
  decoratedPagerVariant.props.children = AttachmentPager(null);

  return (
    <>
      <ItemList<
        AttachmentViewFragment,
        ItemType.ATTACHMENT,
        ItemViewTypeAndProps<ItemType.ATTACHMENT, AttachmentViewVariant>,
        AttachmentListQuery,
        AttachmentListQueryVariables
      >
        type={ItemType.ATTACHMENT}
        variant={viewVariant}
        queryResult={queryResult}
        itemPath="message.attachments"
        pagerVariant={decoratedPagerVariant}
        listVariant={finalListVariant}
        pageSize={pageSize}
        className={cx(className, 'lia-list')}
        loadingSize={null}
        onUpdate={(attachmentConnection: AttachmentConnection): AttachmentListQuery => {
          return {
            message: {
              ...message,
              attachments: attachmentConnection
            }
          };
        }}
      />
      {showPreviewModal && (
        <ImagePreviewModal
          imageOptions={imageOptionsRef.current}
          selectedImage={selectedImage.current}
          show={showPreviewModal}
          onHide={() => {
            selectedImage.current = null;
            setShowPreviewModal(false);
          }}
        />
      )}
    </>
  );
};

export default AttachmentList;
