import type {
  Guide,
  Chapter,
  GuideArticle,
  GuideContentArticle
} from '@aurora/shared-generated/types/graphql-schema-types';

/**
 * Extracts guide content article data from the provided query object.
 *
 * @param guideContentArticle The guide content article.
 * @returns An array containing selected guide and chapter
 *
 */
export function extractGuideContentArticleData(
  guideContentArticle: GuideContentArticle
): [Guide | null, Chapter | null] {
  if (!guideContentArticle) {
    return [null, null];
  }

  const chapter: Chapter =
    guideContentArticle.__typename === 'ChapterArticle' ? guideContentArticle.chapter : null;
  const selectedGuide: Guide = chapter
    ? chapter.guide
    : (guideContentArticle as GuideArticle).guide;

  return [selectedGuide, chapter];
}
