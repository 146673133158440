/*
 * MessageReplyButton.tsx
 * Created on Feb 15, 2020
 *
 * Copyright 2020 Lithium Technologies, Inc.
 * San Francisco, California, U.S.A.  All Rights Reserved.
 *
 * This software is the  confidential and proprietary information
 * of  Lithium  Technologies,  Inc.  ("Confidential Information")
 * You shall not disclose such Confidential Information and shall
 * use  it  only in  accordance  with  the terms of  the  license
 * agreement you entered into with Lithium.
 */
import Button from '@aurora/shared-client/components/common/Button/Button';
import { ButtonVariant } from '@aurora/shared-client/components/common/Button/enums';
import { IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import Icon from '@aurora/shared-client/components/common/Icon/Icon';
import useCommunitySsoProperties from '@aurora/shared-client/components/community/useCommunitySsoProperties';
import SsoRegistrationContext from '@aurora/shared-client/components/context/SsoRegistrationContext/SsoRegistrationContext';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import Icons from '@aurora/shared-client/icons';
import type {
  MessagePagesAndParams,
  MessageReplyPagesAndParams
} from '@aurora/shared-client/routes/endUserRoutes';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import { AuthFlow } from '@aurora/shared-client/types/enums';
import {
  PostMessageType,
  RegistrationStatus
} from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import { checkPolicy } from '@aurora/shared-utils/helpers/objects/PolicyResultHelper';
import UrlHelper from '@aurora/shared-utils/helpers/urls/UrlHelper/UrlHelper';
import React, { useContext } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import ConversationStyleBehaviorHelper from '../../../helpers/boards/ConversationStyleBehaviorHelper';
import { getMessageRouteAndParams } from '../../../helpers/messages/MessageHelper/MessageHelper';
import useGlobalState, {
  EditorLocation,
  GlobalStateType
} from '@aurora/shared-client/helpers/ui/GlobalState';
import { UrlObject, useContextObjectRefFromUrl } from '../../context/useContextObjectFromUrl';
import useTranslation from '../../useTranslation';
import type { MessageReplyProps } from '../MessageView/types';
import localStyles from './MessageReplyButton.module.css';
import EmailVerificationContext from '../../context/EmailVerificationContext/EmailVerificationContext';
import useAuthFlow from '@aurora/shared-client/components/useAuthFlow';

/**
 * Message reply button with count for number of replies on parent message.
 * @constructor
 *
 * @author Shalin Amin, Willi Hyde
 */
const MessageReplyButton: React.FC<React.PropsWithChildren<MessageReplyProps>> = ({
  className,
  message,
  useCount = true,
  useText = true,
  canReply = true,
  setSessionMessageEditingState
}) => {
  const cx = useClassNameMapper(localStyles);
  const { router } = useEndUserRoutes();
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.MESSAGE_REPLY_BUTTON
  );
  const { showSsoRegistrationModal } = useContext(SsoRegistrationContext);
  const [messageEditingState, setMessageEditingState] = useGlobalState(
    GlobalStateType.MESSAGE_EDITING_STATE
  );
  const { id, repliesCount } = message;
  const { id: replyId } = useContextObjectRefFromUrl(UrlObject.REPLY);
  const { addUpdateEmailToast } = useContext(EmailVerificationContext);
  const { isAnonymous, confirmEmailStatus, registrationStatus } = useRegistrationStatus();
  const { data: ssoPropsData, loading: ssoPropsLoading } = useCommunitySsoProperties(module);
  const { triggerAuthFlow } = useAuthFlow();
  const ssoEnabled = checkPolicy(ssoPropsData?.community?.ssoProperties?.ssoEnabled);
  const tenant = useContext(TenantContext);
  const {
    publicConfig: { multiAuthEnabled }
  } = tenant;

  /**
   * Function to navigate to full discussion page i.e. the message view page.
   * This gets triggered whenever we click on the reply button of root message in permalink page.
   */
  async function viewFullDiscussion(): Promise<void> {
    const { messagePage } = ConversationStyleBehaviorHelper.getInstance(message.board);

    await router.pushRoute<MessagePagesAndParams>(messagePage, {
      boardId: message.board.displayId,
      messageSubject: UrlHelper.determineSlugForMessagePath(message),
      messageId: message.uid.toString()
    });
  }

  function createMessageEditingState(messageId: string) {
    return messageEditingState?.messageId === messageId
      ? null
      : {
          messageId: id,
          postMessageType: PostMessageType.Reply,
          editorLocation: EditorLocation.INLINE
        };
  }
  function setMessageState() {
    setMessageEditingState(createMessageEditingState(id));
    if (replyId !== null && message.depth === 0) {
      viewFullDiscussion();
    }
  }

  function handleUserFlow(): void {
    if (!confirmEmailStatus) {
      if (!ssoEnabled) {
        addUpdateEmailToast();
      }
    } else {
      setMessageState();
    }
  }

  async function clickHandler(): Promise<void> {
    if (isAnonymous) {
      const { route, params } = getMessageRouteAndParams(message) as MessageReplyPagesAndParams;
      const relativeUrlForRoute = router.getRelativeUrlForRoute<MessageReplyPagesAndParams>(
        route,
        params
      );
      await triggerAuthFlow(
        (ssoEnabled1: boolean) => {
          if (!ssoEnabled1) {
            setMessageEditingState(null);
          }
        },
        multiAuthEnabled ? AuthFlow.MULTI_AUTH_LOGIN : AuthFlow.LOGIN,
        router.getCurrentRouteAndParams(),
        relativeUrlForRoute
      );
      setSessionMessageEditingState(createMessageEditingState(id));
    } else if (registrationStatus === RegistrationStatus.PartiallyRegistered) {
      showSsoRegistrationModal(true);
      setMessageState();
    } else {
      handleUserFlow();
    }
  }

  if (textLoading || !message || ssoPropsLoading) {
    return null;
  }

  return (
    <>
      <div className={cx('d-flex align-items-center', className)}>
        <Button
          data-testid="ReplyButton"
          aria-label={formatMessage('title')}
          variant={ButtonVariant.LINK}
          className={cx('lia-g-action-btn lia-reply-btn')}
          onClick={clickHandler}
          disabled={!canReply}
        >
          <Icon icon={Icons.CommentIcon} size={IconSize.PX_16} title={formatMessage('title')} />
          {useText && <span className={cx('lia-reply-text')}>{formatMessage('title')}</span>}
        </Button>
        {useCount && (
          <>
            {useText && (
              <div role="separator" className={cx('lia-g-divider-element lia-reply-divider')} />
            )}
            <Button
              aria-label="reply count"
              variant={ButtonVariant.LINK}
              className={cx('lia-g-action-btn lia-count-btn')}
            >
              <span className={cx('lia-g-is-number')}>
                {formatMessage('repliesCount', { count: repliesCount })}
              </span>
            </Button>
          </>
        )}
      </div>
    </>
  );
};

export default MessageReplyButton;
