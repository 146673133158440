import useNodeTagProperties from '@aurora/shared-client/components/nodes/NodeProperties/useNodeTagProperties';
import type { Board } from '@aurora/shared-generated/types/graphql-schema-types';
import dynamic from 'next/dynamic';
import React from 'react';
import type {
  MessageBasicFieldsFragment,
  MessageTagTargetFragment
} from '../../../types/graphql-types';
import TagList from '../TagList/TagList';

const MessageTagEditor = dynamic(() => import('../MessageTagEditor/MessageTagEditor'));

interface Props {
  /**
   * The tag target.
   */
  target: MessageTagTargetFragment & MessageBasicFieldsFragment;
  /**
   * Whether to can tag or not.
   */
  canTag?: boolean;
  /**
   * The number of tags to display.
   */
  pageSize?: number;
  /**
   * If the tags should be clickable or not.
   */
  isTagClickable?: boolean;
}

/**
 * Display message tags.
 *
 * Provides an edit view to users who can tag.
 *
 * @constructor
 *
 * @author Dolan Halbrook
 */
const MessageTags: React.FC<React.PropsWithChildren<Props>> = ({
  target,
  canTag,
  pageSize = 10,
  isTagClickable = true
}) => {
  const { data, loading, error } = useNodeTagProperties(module);

  if (loading || error) {
    return null;
  }

  const {
    tagsEnabled: { failureReason: tagsEnabledFailureReason }
  } = data.coreNode.tagProperties;

  return (
    tagsEnabledFailureReason === null &&
    (!canTag ? (
      <TagList message={target} pageSize={pageSize} />
    ) : (
      <MessageTagEditor
        target={target}
        coreNode={data.coreNode as Board}
        canTag={canTag}
        isTagClickable={isTagClickable}
      />
    ))
  );
};
export default MessageTags;
