import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import type { MessageIntroductionFragment } from '../../../types/graphql-types';
import localStyles from './MessageIntroduction.module.css';

interface Props {
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;
  /**
   * Set a custom element for this component.
   */
  as?: React.ElementType;
  /**
   * The message to display the teaser for.
   */
  message: MessageIntroductionFragment;
}

/**
 * Message Introduction.
 * @author Aditi Agarwal
 */
const MessageIntroduction: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  as: Component = 'h2',
  message
}) => {
  const cx = useClassNameMapper(localStyles);
  const { introduction } = message;

  return introduction ? (
    <Component
      className={cx(className, 'lia-message-introduction h4')}
      dangerouslySetInnerHTML={introduction ? { __html: introduction } : null}
    />
  ) : null;
};

export default MessageIntroduction;
