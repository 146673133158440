import { isEventOngoing } from '@aurora/shared-client/helpers/forms/DateHelper/DateHelper';
import { useEffect, useRef, useState } from 'react';
import { useInterval } from 'react-use';

/**
 * Custom hook for auto refreshing the component.
 *
 * @author Aditi Agarwal
 */

export default function useAutoRefresh(
  startTime: number,
  endTime: number,
  liveVideoIframe: string,
  delta = 0
): boolean {
  let isOccasionInProgress = isEventOngoing(startTime, endTime, delta);
  const [isInProgress, setIsInProgress] = useState<boolean>(isOccasionInProgress);
  const [delay, setDelay] = useState<number>(60000);
  const isInitializedRef = useRef<boolean>(false);

  useInterval(() => {
    if (liveVideoIframe) {
      isOccasionInProgress = isEventOngoing(startTime, endTime, delta);
      if (isOccasionInProgress !== isInProgress) {
        isInitializedRef.current = true;
        setIsInProgress(isOccasionInProgress);
      }
    }
  }, delay);

  useEffect(() => {
    if (liveVideoIframe) {
      return () => {
        if (isInitializedRef.current) {
          setDelay(null);
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isInProgress;
}
