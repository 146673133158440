import Button from '@aurora/shared-client/components/common/Button/Button';
import { ButtonVariant } from '@aurora/shared-client/components/common/Button/enums';
import { IconColor, IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import Icon from '@aurora/shared-client/components/common/Icon/Icon';
import ThemedContainer from '@aurora/shared-client/components/common/ThemedContainer/ThemedContainer';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import useNodePolicies from '@aurora/shared-client/components/nodes/useNodePolicies';
import useDateTime from '@aurora/shared-client/components/useDateTime';
import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import { canViewEscalations } from '@aurora/shared-client/helpers/nodes/NodePolicyHelper';
import Icons from '@aurora/shared-client/icons';
import type { SalesforceMessage } from '@aurora/shared-generated/types/graphql-schema-types';
import { ConversationStyle } from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import dynamic from 'next/dynamic';
import React, { useContext, useState } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import type {
  EscalatedMessageFragment,
  SalesforceCaseViewFragment,
  SalesforceEscalatedCaseQuery,
  SalesforceEscalatedCaseQueryVariables
} from '../../../types/graphql-types';
import useTranslation from '../../useTranslation';
import localStyles from './EscalatedMessageBanner.module.pcss';
import salesforceEscalatedCaseQuery from './SalesforceEscalatedCase.query.graphql';

const EscalatedCaseDetailsModal = dynamic(
  () => import('./EscalatedCaseDetailsModal/EscalatedCaseDetailsModal'),
  {
    ssr: false
  }
);

interface Props {
  /**
   * The message information
   */
  message: EscalatedMessageFragment;
  /**
   * Classname(s) to apply
   */
  className?: string;
}

/**
 * Banner to be displayed for escalated forum root and reply messages.
 *
 * @author Manasvini Arul
 */
const EscalatedMessageBanner: React.FC<React.PropsWithChildren<Props>> = ({
  message,
  className
}) => {
  const cx = useClassNameMapper(localStyles);
  const {
    formatMessage,
    loading: textLoading,
    FormattedMessage
  } = useTranslation(EndUserComponent.ESCALATED_MESSAGE_BANNER);
  const {
    publicConfig: { salesforceIntegrationEnabled }
  } = useContext(TenantContext);
  const isForumMessage = message.board.conversationStyle === ConversationStyle.Forum;
  const { formatAbsoluteDateTime } = useDateTime();
  const { isAnonymous } = useRegistrationStatus();
  const isEscalatedMessage = (message as SalesforceMessage).isEscalated;
  const { data: nodePoliciesData, loading: nodePoliciesLoading } = useNodePolicies(
    module,
    {
      useCanViewEscalations: true
    },
    isAnonymous || !(salesforceIntegrationEnabled && isForumMessage && isEscalatedMessage)
  );
  const [showCaseDetailsModal, setShowCaseDetailsModal] = useState<boolean>(false);

  const queryResult = useQueryWithTracing<
    SalesforceEscalatedCaseQuery,
    SalesforceEscalatedCaseQueryVariables
  >(module, salesforceEscalatedCaseQuery, {
    variables: {
      id: message.id
    },
    fetchPolicy: 'network-only',
    skip: isAnonymous || !(salesforceIntegrationEnabled && isForumMessage && isEscalatedMessage)
  });

  if (
    textLoading ||
    nodePoliciesLoading ||
    queryResult.loading ||
    !queryResult?.data ||
    !nodePoliciesData?.coreNode ||
    !isEscalatedMessage
  ) {
    return null;
  }

  const escalatedMessage: SalesforceCaseViewFragment =
    queryResult.data.salesforceEscalatedCase.result;

  if (!escalatedMessage) {
    return null;
  }

  const shouldDisplayBanner: boolean =
    salesforceIntegrationEnabled && isForumMessage && canViewEscalations(nodePoliciesData.coreNode);
  const isEscalatedMessageReply: boolean = escalatedMessage.message?.depth !== 0;

  /**
   * Renders the link to case details modal
   */
  function renderCaseDetailsModal(): React.ReactElement {
    return (
      <EscalatedCaseDetailsModal
        show={showCaseDetailsModal}
        onHide={() => setShowCaseDetailsModal(false)}
        message={escalatedMessage}
      />
    );
  }

  /**
   * Function to render the banner for escalated message
   */
  function renderBannerForEscalatedMessage(): React.ReactElement {
    return (
      <ThemedContainer className={cx('lia-g-pr-0', className)}>
        <div
          className={cx('lia-message-container', {
            'lia-reply-container': isEscalatedMessageReply
          })}
          data-testid="EscalatedMessageBanner"
        >
          {!isEscalatedMessageReply && (
            <span className={cx('lia-g-mr-10')}>
              <Icon icon={Icons.InfoIcon} color={IconColor.INFO} size={IconSize.PX_16} />
            </span>
          )}
          <div>
            <FormattedMessage
              id="escalationMessage"
              values={{
                username: escalatedMessage.escalatedBy
                  ? escalatedMessage.escalatedBy.login
                  : escalatedMessage.isAutomaticEscalation
                  ? formatMessage('automaticEscalation')
                  : formatMessage('anonymous'),
                date: formatAbsoluteDateTime(escalatedMessage.createdDate),
                span: (...chunks) => <span className={cx('font-weight-bold')}>{chunks}</span>
              }}
            />
          </div>
          <Button
            className={cx('lia-case-details-button lia-g-ml-10')}
            onClick={() => setShowCaseDetailsModal(true)}
            data-testid="ViewCaseDetailsButton"
            variant={ButtonVariant.UNSTYLED}
          >
            {formatMessage('viewDetails')}
          </Button>
        </div>
        {showCaseDetailsModal && renderCaseDetailsModal()}
      </ThemedContainer>
    );
  }

  return shouldDisplayBanner && renderBannerForEscalatedMessage();
};

export default EscalatedMessageBanner;
