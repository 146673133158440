import type { PropsWithChildren } from 'react';
import React, { useEffect, useRef } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import localStyles from './ArticleLink.module.pcss';
import useSeoProperties from '@aurora/shared-client/components/seo/useSeoProperties';
import type { MessagePagesAndParams } from '@aurora/shared-client/routes/endUserRoutes';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import { EndUserComponent, EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import ConversationStyleBehaviorHelper from '../../../../helpers/boards/ConversationStyleBehaviorHelper';
import type { KbArticleViewFragment } from '../../../../types/graphql-types';
import type { Board } from '@aurora/shared-generated/types/graphql-schema-types';
import useTranslation from '../../../useTranslation';
import type { ParsedUrlQuery } from '@aurora/shared-utils/helpers/urls/NextRoutes/Route';
import UrlHelper from '@aurora/shared-utils/helpers/urls/UrlHelper/UrlHelper';

export enum LinkStyleType {
  NODE = 'node',
  CONTENT = 'content'
}

/**
 * Props for the ArticleLink component.
 */
interface Props {
  /**
   * The article content.
   */
  article: KbArticleViewFragment;
  /**
   * The ID of the selected guide associated with the article.
   */
  selectedGuideId?: string;
  /**
   * The article's guide id in current list.
   */
  parentGuideId?: string;
  /**
   * Context message id
   */
  contextMessageId?: string;
  /**
   * class for the link
   */
  className?: string;
  /**
   * shallow render on click
   */
  useShallow?: boolean;
  /**
   * Open link in new tab
   */
  openInNewTab?: boolean;
  /**
   * Whether to use manual sort navigation.
   */
  useManualSortNav?: boolean;
  /**
   * Link style type
   */
  linkStyleType?: LinkStyleType;
  /**
   * Add's additional query params to the article link URL;  can be used
   * for links that use a different structure to store the node and message Id in the URL
   * such as the guides page which uses a different URL structure for
   * storing the node and article id to prevent the context node and message
   * from being reloaded when navigating.
   */
  additionalQueryParams?: ParsedUrlQuery;
}

/**
 * Guide item element
 *
 * @author Amit Agrawal, Kunal Punjrath
 */
const ArticleLink: React.FC<PropsWithChildren<Props>> = ({
  article,
  parentGuideId,
  selectedGuideId,
  contextMessageId,
  className,
  useShallow,
  openInNewTab,
  useManualSortNav = false,
  linkStyleType,
  children,
  additionalQueryParams = {}
}) => {
  const cx = useClassNameMapper(localStyles);
  const { formatMessage, loading: textLoading } = useTranslation(EndUserComponent.ARTICLE_LINK);
  const { Link, router } = useEndUserRoutes();
  const articleIdParam = router.getUnwrappedQueryParam(EndUserQueryParams.ARTICLE_ID);
  const { getCaseSensitivePath } = useSeoProperties();
  const articleRef = useRef<HTMLAnchorElement>(null);

  let isCurrentArticle = article?.id === (articleIdParam ?? contextMessageId);

  if (parentGuideId) {
    isCurrentArticle = parentGuideId === selectedGuideId && isCurrentArticle;
  }

  /*
   *  Scrolls the referenced article into view if it is the current article.
   */
  useEffect(() => {
    if (isCurrentArticle && articleRef.current) {
      const target = articleRef.current;
      const container = target.offsetParent;
      container?.scrollTo({
        top: target.offsetTop,
        behavior: 'smooth'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (textLoading) {
    return null;
  }

  if (!article) {
    return (
      <span className={cx(className, 'lia-restricted-article')}>
        {formatMessage('restrictedArticle')}
      </span>
    );
  }

  const { subject, board, uid } = article;
  const { messagePage } = ConversationStyleBehaviorHelper.getInstance(board as Board);

  const messageParams: MessagePagesAndParams['params'] = {
    boardId: getCaseSensitivePath(board.displayId),
    messageSubject: String(UrlHelper.determineSlugForMessagePath({ subject })),
    messageId: uid.toString()
  };

  let queryParams = {};
  if (useManualSortNav) {
    queryParams = { [EndUserQueryParams.SHOW_MANUAL_SORT_NAV]: 'true' };
  } else if (parentGuideId) {
    queryParams = { [EndUserQueryParams.GUIDE_ID]: parentGuideId };
  }

  return (
    <Link<MessagePagesAndParams>
      key={article.id}
      route={messagePage}
      params={messageParams}
      query={{ ...queryParams, ...additionalQueryParams }}
      shallow={useShallow}
      passHref
      legacyBehavior={true}
    >
      {children ?? (
        <a
          className={cx(
            className,
            'lia-g-clamp lia-g-clamp-2',
            {
              'lia-is-active': isCurrentArticle && linkStyleType === LinkStyleType.CONTENT
            },
            {
              [`lia-style-${linkStyleType}`]: linkStyleType
            }
          )}
          target={openInNewTab ? '_blank' : '_self'}
          ref={articleRef}
        >
          {subject}
        </a>
      )}
    </Link>
  );
};

export default ArticleLink;
