import React from 'react';
import useTranslation from '../../useTranslation';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import localStyles from './GuideBottomNavigation.module.pcss';
import { useClassNameMapper } from 'react-bootstrap';
import useGuideNavigationForArticle, {
  NavigationType
} from '../../tkbs/TkbArticleWidget/useGuideNavigationForArticle';
import GuideBottomNavigationItem from '../GuideBottomNavigationItem/GuideBottomNavigationItem';

interface Props {
  /**
   * Class to add to nav
   */
  className?: string;
}

/**
 * Guide Bottom navigation
 *
 * @author Kunal Punjrath
 */
const GuideBottomNavigation: React.FC<Props> = ({ className }) => {
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.GUIDE_BOTTOM_NAVIGATION
  );
  const cx = useClassNameMapper(localStyles);
  const {
    navigationType,
    previousArticle,
    nextArticle,
    guideIdOfPreviousArticle,
    guideIdOfNextArticle
  } = useGuideNavigationForArticle(module);

  if (textLoading) {
    return null;
  }

  const useManualSortNav = navigationType === NavigationType.MANUALLY_SORTED_NAVIGATION;
  const showNavigation = !!(previousArticle || nextArticle);

  return (
    <>
      {showNavigation && (
        <nav
          aria-label={formatMessage('nav.label')}
          className={cx('lia-guide-navigation-container', className)}
        >
          <ul className={cx('lia-guide-navigation')}>
            {previousArticle && (
              <GuideBottomNavigationItem
                currentGuideId={guideIdOfPreviousArticle}
                linkHeading={formatMessage('nav.previous')}
                article={previousArticle}
                useManualSortNav={useManualSortNav}
              />
            )}
            {nextArticle && (
              <GuideBottomNavigationItem
                alignRight
                currentGuideId={guideIdOfNextArticle}
                linkHeading={formatMessage('nav.next')}
                article={nextArticle}
                useManualSortNav={useManualSortNav}
              />
            )}
          </ul>
        </nav>
      )}
    </>
  );
};

export default GuideBottomNavigation;
