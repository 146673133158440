import Button from '@aurora/shared-client/components/common/Button/Button';
import { ButtonVariant } from '@aurora/shared-client/components/common/Button/enums';
import { IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import type AsyncSearchableSelectFieldEntry from '@aurora/shared-client/components/form/AsyncSearchableSelectField/AsyncSearchableSelectFieldEntry';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import NodeAvatar from '@aurora/shared-client/components/nodes/NodeAvatar/NodeAvatar';
import NodeDescription from '@aurora/shared-client/components/nodes/NodeDescription/NodeDescription';
import NodeIcon from '@aurora/shared-client/components/nodes/NodeIcon/NodeIcon';
import NodeTitle from '@aurora/shared-client/components/nodes/NodeTitle/NodeTitle';
import useAuthFlow from '@aurora/shared-client/components/useAuthFlow';
import useImperativeQueryWithTracing from '@aurora/shared-client/components/useImperativeQueryWithTracing';
import UserAvatar from '@aurora/shared-client/components/users/UserAvatar/UserAvatar';
import UserBiography from '@aurora/shared-client/components/users/UserBiography/UserBiography';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import UserRank from '@aurora/shared-client/components/users/UserRank/UserRank';
import { AuthFlow } from '@aurora/shared-client/types/enums';
import type {
  Article,
  Message,
  User,
  UserConnection,
  UserEdge
} from '@aurora/shared-generated/types/graphql-schema-types';
import { ConversationStyle } from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import dynamic from 'next/dynamic';
import React, { useContext, useState } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { useMeasure } from 'react-use';
import useGlobalState, { GlobalStateType } from '@aurora/shared-client/helpers/ui/GlobalState';
import { Breakpoint, SubscriptionActionVariant } from '../../../types/enums';
import type {
  ContributorFieldsFragment,
  MessageAuthorFragment,
  MessageViewFragment,
  UserViewsQuery,
  UserViewsQueryVariables
} from '../../../types/graphql-types';
import EmailVerificationContext from '../../context/EmailVerificationContext/EmailVerificationContext';
import NodeLink from '../../nodes/NodeLink/NodeLink';
import NodeSubscriptionAction from '../../nodes/NodeSubscriptionAction/NodeSubscriptionAction';
import UserLink from '../../users/UserLink/UserLink';
import UserRegistrationDate from '../../users/UserRegistrationDate/UserRegistrationDate';
import usersQuery from '../../users/UserViews.query.graphql';
import useTranslation from '../../useTranslation';
import { useCurrentOrPreviewMessage, useIsPreviewMode } from '../useCurrentOrPreviewMessage';
import localStyles from './MessageAuthorBio.module.pcss';

const PrivateConversationCreationModal = dynamic(
  () =>
    import(
      /* webpackChunkName: "modal-PrivateConversationCreationModal" */ '../../inbox/PrivateConversationCreationModal/PrivateConversationCreationModal'
    ),
  { ssr: false }
);

interface Props {
  /**
   * The message to display author bio for.
   */
  message: Pick<Message, 'id'> & MessageViewFragment;
  /**
   * Classname applied to the outermost element.
   */
  className?: string;
}

/**
 * Message Author Bio.
 *
 * @author Sravan Reddy, Willi Hyde
 */
const MessageAuthorBio: React.FC<React.PropsWithChildren<Props>> = ({ message, className }) => {
  const cx = useClassNameMapper(localStyles);
  const { contextNode, contextMessage } = useContext(AppContext);
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.MESSAGE_AUTHOR_BIO
  );
  const [, setMessageEditingState] = useGlobalState(GlobalStateType.MESSAGE_EDITING_STATE);
  const [showModal, setShowModal] = useState(false);
  const [users, setUsers] = useState<AsyncSearchableSelectFieldEntry<User>[]>([]);
  const { isAnonymous, confirmEmailStatus } = useRegistrationStatus();
  const [reference, { width }] = useMeasure();
  const iconSize = width > Breakpoint.SM ? IconSize.PX_80 : IconSize.PX_50;
  const author = (message as MessageAuthorFragment)?.author;
  const { addUpdateEmailToast } = useContext(EmailVerificationContext);
  const fetchUsers = useImperativeQueryWithTracing<UserViewsQuery, UserViewsQueryVariables>(
    module,
    usersQuery
  );
  const { triggerAuthFlow } = useAuthFlow();
  const tenant = useContext(TenantContext);
  const {
    publicConfig: { multiAuthEnabled }
  } = tenant;

  const { message: currentOrRevisionMessage } = useCurrentOrPreviewMessage(
    message,
    useIsPreviewMode()
  );
  const currentMessage = currentOrRevisionMessage ?? message;

  /**
   *  Function to handle send message action.
   *
   *  @param user author/contributor.
   */
  async function handleClick(user: ContributorFieldsFragment): Promise<void> {
    if (isAnonymous) {
      await triggerAuthFlow(
        (ssoEnabled: boolean) => {
          if (!ssoEnabled) {
            setMessageEditingState(null);
          }
        },
        multiAuthEnabled ? AuthFlow.MULTI_AUTH_LOGIN : AuthFlow.LOGIN
      );
    } else {
      if (!confirmEmailStatus) {
        addUpdateEmailToast();
      } else {
        const userData = await fetchUsers({
          constraints: { id: { eq: user.id } },
          useAvatar: true
        });
        setUsers(
          userData.data.users.edges.map(u => ({
            label: u.node.login,
            value: u.node.login,
            entity: u.node as User
          }))
        );
        setShowModal(true);
      }
    }
  }

  if (textLoading) {
    return null;
  }

  /**
   * Renders the author bio details.
   *
   * @param user author, contributor or co-author for the message.
   */
  function RenderBio({ user }: { user: ContributorFieldsFragment }): React.ReactElement {
    return (
      <div className={cx('d-flex align-items-center')} key={user?.id}>
        <UserLink user={user} className={cx('align-self-start')}>
          <UserAvatar user={user} size={iconSize} />
        </UserLink>
        <div className={cx('lia-footer-details-wrapper')}>
          <div className={cx('lia-footer-details')}>
            <div className={cx('d-flex align-items-center')}>
              <UserLink className={cx('lia-g-divider lia-author-login')} user={user}>
                {user?.login}
              </UserLink>
              {user?.uid !== -1 && <UserRank className={cx('lia-user-rank')} user={user} />}
            </div>
            <UserBiography className={cx('lia-footer-description')} user={user} clampLines={5} />
            {!user?.biography && !user?.deleted && (
              <UserRegistrationDate
                user={user}
                textKey={'withPrefix'}
                className={cx('lia-footer-description')}
                as={'div'}
              />
            )}
          </div>
          {/*to be replaced with Follow Action*/}
          {user?.uid !== -1 && !user?.deleted && (
            <>
              <div className={cx('lia-action d-none')}>
                {(isAnonymous || !isAnonymous) && (
                  <Button
                    variant={ButtonVariant.SECONDARY}
                    aria-label={formatMessage('sendMessage')}
                    title={formatMessage('sendMessage')}
                    tabIndex={0}
                    onClick={() => handleClick(user)}
                    data-testid="SendMessage-Button"
                  >
                    {formatMessage('sendMessage')}
                  </Button>
                )}
              </div>
              <div className={cx('lia-action')}>
                <UserLink
                  user={user}
                  useHoverCard={false}
                  passHref
                  className={cx('btn btn-secondary')}
                >
                  {formatMessage('userProfile')}
                </UserLink>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }

  /**
   * Renders the place information.
   */
  function renderPlaceInformation(): React.ReactElement {
    return (
      contextMessage?.board?.conversationStyle === ConversationStyle.Blog && (
        <div className={cx('d-flex align-items-center')}>
          <NodeLink
            node={contextNode}
            className={cx('align-self-start')}
            ariaLabel={formatMessage('iconlink', {
              name: contextNode.title,
              type: contextNode.nodeType
            })}
            useHoverCard
          >
            <NodeAvatar
              node={contextNode}
              size={iconSize}
              fallback={(): JSX.Element => <NodeIcon node={contextNode} size={iconSize} useFrame />}
            />
          </NodeLink>
          <div className={cx('lia-footer-details-wrapper')}>
            <div className={cx('lia-footer-details')}>
              <NodeLink node={contextNode} className={cx('lia-node-title')} useHoverCard>
                <NodeTitle className={cx('lia-g-mb-0')} node={contextNode} as={'span'} />
              </NodeLink>
              <NodeDescription
                node={contextMessage?.board}
                as="div"
                clampLines={5}
                className={cx(`lia-g-card-subtitle lia-g-card-item lia-footer-description`)}
              />
              {!contextNode?.description && (
                <div className={cx('lia-footer-description')}>{formatMessage('actionMessage')}</div>
              )}
            </div>
            <div className={cx('lia-action')}>
              <NodeSubscriptionAction
                node={contextNode}
                variant={SubscriptionActionVariant.BUTTON}
                useSuccessFeedback={true}
                useHoverEffect={true}
              />
            </div>
          </div>
        </div>
      )
    );
  }

  function toContributorFields(connection: UserConnection): Array<ContributorFieldsFragment> {
    return connection?.edges?.map(edge => (edge as UserEdge)?.node) ?? [];
  }

  return (
    <>
      <article
        className={cx('lia-footer-wrapper', className)}
        data-testid="MessageAuthorBio"
        ref={reference}
      >
        <RenderBio key={author.id} user={author as ContributorFieldsFragment} />
        {toContributorFields((currentMessage as Article).coAuthors as UserConnection).map(user => {
          return <RenderBio key={user.id} user={user} />;
        })}
        {toContributorFields((currentMessage as Article).contributors as UserConnection).map(
          user => {
            return <RenderBio key={user.id} user={user} />;
          }
        )}
        {renderPlaceInformation()}
        {showModal && (
          <PrivateConversationCreationModal
            show={showModal}
            onHide={() => {
              setShowModal(false);
              setUsers([]);
            }}
            isSendBroadcast={false}
            recipientUsers={users}
          />
        )}
      </article>
    </>
  );
};

export default MessageAuthorBio;
