import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import useTranslation from '../../useTranslation';
import { useUIDSeed } from 'react-uid';
import Button from '@aurora/shared-client/components/common/Button/Button';
import { ButtonVariant } from '@aurora/shared-client/components/common/Button/enums';
import { setExternalVideoConsentCookie } from '../CookieBanner/CookieHelper';
import localStyles from './VideoConsentBanner.module.pcss';
import { IconSize, IconColor } from '@aurora/shared-client/components/common/Icon/enums';
import Icon from '@aurora/shared-client/components/common/Icon/Icon';
import Icons from '@aurora/shared-client/icons';

interface Props {
  /**
   * Callback function when the banner is to be hidden
   *
   * @callback
   */
  onHide: () => void;
  /**
   * The occasion livestream url
   */
  occasionLiveStreamUrl: string;
}

/**
 * Renders a video consent banner component.
 *
 * @author Akash Goenka
 */
const VideoConsentBanner: React.FC<React.PropsWithChildren<Props>> = ({
  onHide,
  occasionLiveStreamUrl
}) => {
  const cx = useClassNameMapper(localStyles);
  const i18n = useTranslation(EndUserComponent.EXTERNAL_VIDEO_CONSENT_BANNER);
  const { formatMessage, FormattedMessage } = i18n;
  const uidSeed = useUIDSeed();
  const ariaBannerTitleId = uidSeed('videoConsentBannerTitle');

  return (
    <div
      className={cx('lia-banner')}
      role="alertdialog"
      aria-labelledby={ariaBannerTitleId}
      aria-describedby={uidSeed('videoConsentBannerBody')}
      data-testid="VideoConsentBanner"
    >
      <p className={cx('lia-g-mb-0')} id={ariaBannerTitleId}>
        <FormattedMessage
          id="bannerTitle"
          values={{
            Link: function renderChunks(chunks): React.ReactNode {
              return (
                <a href={occasionLiveStreamUrl} target="_blank" rel="noreferrer">
                  {chunks}
                  <Icon
                    icon={Icons.ExternalLinkIcon}
                    className={cx('lia-link-icon')}
                    size={IconSize.PX_14}
                    color={IconColor.LINK_COLOR}
                  />
                </a>
              );
            }
          }}
        />
      </p>
      <Button
        size="sm"
        variant={ButtonVariant.SECONDARY}
        className={cx('lia-btn')}
        onClick={() => {
          setExternalVideoConsentCookie();
          onHide();
        }}
        data-testid="VideoConsentBanner.AcceptButton"
      >
        {formatMessage('buttonTitle')}
      </Button>
    </div>
  );
};

export default VideoConsentBanner;
